<template>
  <div class="upload">
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar color="greyBase" dark :extended="!$vuetify.breakpoint.mobile" flat extension-height="55">
        <v-toolbar-title>
          <b class="mr-3">Inspection Document Uploads</b>
        </v-toolbar-title>
      </v-toolbar>

      <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
        'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
        'border-radius': !$vuetify.breakpoint.mobile ? '30px' : '0px',
      }">
        <v-card-text :style="{
          height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
          'overflow-y': 'auto',
        }">
          <v-tabs v-model="selectedTab">
            <v-tab href="#consignment" @click="activeTab != 'inspectionDocuments' ? $router.push({ hash: '#inspectionDocuments' }) : ''">Inspection Documents</v-tab>
            <!-- <v-tab href="#corrupt" @click="activeTab != 'corruptDocuments' ? $router.push({ hash: '#corruptDocuments' }) : ''">Corrupt Documents</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="consignment">
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-row>
                    <v-col cols="12" sm="2" class="mr-0 pr-0 mt-2">
                      <v-card flat style="border-radius: 20px; height: 75vh">
                        <v-card-text>
                          <v-list dense subheader>
                            <v-subheader><v-icon color="secondary" class="mr-2">filter_alt</v-icon>Filter</v-subheader>
                            <v-divider></v-divider>
                            <v-text-field class="my-2" hide-details prepend-inner-icon="search" label="Search sscc" outlined dense clearable
                              v-model="searchSSCC"></v-text-field>
                          </v-list>
                          <v-list dense style="max-height: 50vh;overflow-y: auto" :key="documentKey" subheader>
                            <v-divider></v-divider>
                            <v-subheader><v-icon color="secondary" class="mr-2">upload</v-icon> Uploads <v-spacer></v-spacer><v-chip v-if="fileList.length>0">{{ fileList.length }}</v-chip></v-subheader>
                            <v-divider></v-divider>
                            <v-list-item v-if="fileList.length == 0">
                              <v-list-item-content class="text-center">
                                <span style="font-size: 12px ; color: grey">No files uploaded</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-for="(file, i) in fileList" :key="file.id">
                              <v-list-item-action style="width: 25px" class="mx-0 px-0 pr-1">
                                <v-img contain style="width: 20px; height: 20px" :src="getIcon(file.file.name)">
                                </v-img>
                              </v-list-item-action>
                              <v-list-item-content class="ml-0 pl-0">
                                <v-list-item-title style="font-size: 12px">
                                  {{ file.file.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 12px">
                                  {{ file.file.type }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action :key="file.progress">
                                <v-btn v-if="!file.progress || file.progress == 0" icon color="red"
                                  @click="fileList.splice(i, 1)">
                                  <v-icon>close</v-icon>
                                </v-btn>
                                <span v-else-if="file.progress < 100">
                                  {{ file.progress }}%
                                </span>
                                <span v-else-if="file.progress == 100">
                                  <v-icon color="green" class="mr-1">check</v-icon>
                                </span>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>

                    </v-col>
                    <v-col cols="12" sm="10" class="ml-0 pl-0">
                      <v-row class="mb-2">
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="4">
                          <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable
                            placeholder="Search">
                            <template slot="prepend">
                              <el-button @click="addFiles()">
                                  <v-icon small>add</v-icon>
                                </el-button>
</template>
                            <!-- <template slot="append">
                              <v-badge :content="1" :value="ssccFilter" color="primary" offset-y="10">
                                <el-button @click="filterDialog = !filterDialog" el-tooltip="Advanced Filter">
                                  <v-icon small>filter_alt</v-icon>
                                </el-button>
                              </v-badge>
                            </template> -->
                          </el-input>
                        </v-col>
                      </v-row>
                      <v-data-table id="upload-table" :loading="loading" :headers="contentHeaders"
                        :items="consignmentNotes.data" fixed-header color="primary" item-key="id" height="70vh"
                        style="cursor: pointer" hide-default-footer disable-pagination @click:row="viewPallets">
                        <template v-slot:[`item.icon`]="{ item }">
                          <v-row justify="center">
                            <v-img contain v-if="getIcon(item.name)" style="width: 20px; height: 20px"
                              :src="getIcon(item.name)">
                            </v-img>
                          </v-row>
                        </template>
                        <template v-slot:[`item.updatedAt`]="{ item }">
                          {{ formatDate(item.updatedAt) }}
                        </template>
                        <template v-slot:[`item.classification`]="{ item }">
                          <v-row v-if="documentClassifications(item).length <= 3" justify="center">
                            <v-chip small label outlined v-for="docClassification in documentClassifications(item)"
                              :key="docClassification.id">
                              <v-icon small left>label</v-icon> {{ docClassification.supportingDocument.name }}
                            </v-chip>
                          </v-row>
                          <v-row v-else justify="center">
                            <v-chip small label outlined
                              v-for="docClassification in documentClassifications(item).slice(0, 3)"
                              :key="docClassification.id">
                              <v-icon small left>label</v-icon> {{ docClassification.supportingDocument.name }}
                            </v-chip>
                            <span> + {{ documentClassifications(item).length - 3 }} other(s)</span>
                          </v-row>

                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                        :page-size.sync="params.limit" :page-sizes="[24, 48, 72]" :layout="!$vuetify.breakpoint.mobile
                          ? 'sizes, prev, pager, next, jumper, total'
                          : 'prev, pager, next'
                          " :total="consignmentNotes.total">
                      </el-pagination>
                    </v-col>
                  </v-row>


                </v-card-text>
              </v-card>
            </v-tab-item>

            <!-- Corrupt notes -->
            <v-tab-item value="corrupt">
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-row justify="end">
                    <v-col cols="12" sm="6" md="4">
                      <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="searchCorrupt" clearable
                        placeholder="Search">
                      </el-input>
                    </v-col>
                    <v-col cols="12">
                      <v-card flat>
                        <v-card-text style="
                            min-height: 10vh;
                            height: 60vh;
                            overflow-y: auto;
                          " v-loading="loadingCorrupt" :key="documentKey">
                          <v-row>
                            <v-col cols="12" v-if="!loadingCorrupt && filterCorrupt && filterCorrupt.length == 0
                              " class="text-center">
                              <span style="color: grey">No corrupt documents found</span></v-col>

                            <v-col cols="12" sm="4" md="3" lg="2" v-for="(note, index) in filterCorrupt" :key="note.id">
                              <v-hover v-slot:default="{ hover }">
                                <v-card style="border-radius: 20px" outlined class="pa-0 ma-0">
                                  <v-expand-transition>
                                    <v-card v-if="hover"
                                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-3 white--text"
                                      style="height: 50%">
                                      <v-card-text class="py-0 my-0">
                                        <v-row justify="space-around">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn v-bind="attrs" v-on="on" icon @click="downloadDocument(note)"
                                                :loading="note.loading"><v-icon>download</v-icon></v-btn>
                                            </template>
                                            <span style="font-size: 12px">Download</span>
                                          </v-tooltip>
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn v-bind="attrs" v-on="on" icon @click="
                                                deleteCorruptDocument(
                                                  note,
                                                  index
                                                )
                                                "><v-icon>delete</v-icon></v-btn>
                                            </template>
                                            <span style="font-size: 12px">Delete</span>
                                          </v-tooltip>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </v-expand-transition>
                                  <v-card-text class="my-0 mx-0 px-0">
                                    <v-row justify="center">
                                      <v-col cols="12" class="my-0 py-0">
                                        <v-list-item class="my-0 py-0">
                                          <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                                            <v-img class="mr-2" style="height: 30px; width: 30px"
                                              :src="getIcon(note.name)">
                                            </v-img>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title class="text-left" style="font-size: 14px">
                                              {{ note.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="note.user">
                                              <v-icon small>person</v-icon>
                                              {{ note.user.firstname }}
                                              {{ note.user.surname }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle style="font-size: 12px">
                                              {{ formatDate(note.createdAt) }}
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle style="font-size: 12px">
                                              <v-chip label small v-for="label in note.shipmentDocumentClassifications"
                                                :key="label.id">{{
                                                  label.supportingDocument
                                                    .abbreviation
                                                }}</v-chip>
                                              <!-- {{ formatDate(note.createdAt) }} -->
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-hover>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <!-- <v-col cols="12" class="text-center">
              <el-pagination
                style="color: var(--v-primaryText-base)"
                :current-page.sync="page"
                :pager-count="5"
                :page-size.sync="params.limit"
                :page-sizes="[24, 48, 72]"
                :layout="
                  !$vuetify.breakpoint.mobile
                    ? 'sizes, prev, pager, next, jumper, total'
                    : 'prev, pager, next'
                "
                :total="consignmentNotes.total"
              >
              </el-pagination>
            </v-col> -->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog v-model="filterDialog" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Search Pallet ID </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDialog = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field placeholder="Enter Pallet ID" outlined dense clearable v-model="searchSSCC">
          </v-text-field>
          <v-row justify="center" class="mt-3">
            <v-btn color="danger" small text style="text-transform: none" @click="
              (searchSSCC = null),
              (ssccFilter = false),
              (filterDialog = false),
              getConsignmentNotes()
              ">Clear Filters</v-btn>
            <v-btn color="success" small text :disabled="!searchSSCC || (searchSSCC && searchSSCC.length < 9)"
              style="text-transform: none" @click="
                getSSCCNotes(), (ssccFilter = true), (filterDialog = false)
                ">Apply Filter</v-btn>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

    <v-dialog v-model="palletModal" width="1400px" :fullscreen="$vuetify.breakpoint.mobile || fullscreen">
      <v-card v-if="palletDocument">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ palletDocument.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="$refs.pdf.print()">
                    <v-icon>print</v-icon> Print
                </v-btn> -->
          <!-- <v-btn v-if="documentTab=='preview'" @click="annotate" icon :color="annotated?'blue':'primaryText'"><v-icon>highlight_alt</v-icon></v-btn> -->
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon @click="downloadDocument(palletDocument)"
            :loading="palletDocument.loading"><v-icon>download</v-icon></v-btn>
          <v-btn text @click="
            (palletModal = false), ((palletDocument = {}), (pallets = []))
            ">X</v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="2">
              <v-list style="max-height: 70vh; overflow-y: auto">
                <v-subheader>Classifications</v-subheader>
                <v-divider></v-divider>
                <v-list dense subheader>
                  <v-list-item v-for="classification in documentClassifications(palletDocument)" :key="classification.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ classification.supportingDocument.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ classification.supportingDocument.abbreviation }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="documentClassifications(palletDocument).length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No classifications</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>

                <v-subheader>Containers</v-subheader>
                <v-divider></v-divider>

                <v-list dense subheader>
                  <v-list-item v-for="container in palletDocument.containerDocuments" :key="container.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ container.containerNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="container.page">
                        Page {{ container.page }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!palletDocument.containerDocuments || palletDocument.containerDocuments.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No containers detected</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-subheader>Pallets</v-subheader>
                <v-divider></v-divider>
                <v-progress-linear v-if="loadingPallets" indeterminate color="primary"></v-progress-linear>
                <v-list-item style="height: 15px" v-for="pallet in pallets" :key="pallet.id">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">
                            {{ pallet.sscc }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">
                            Page {{ pallet.page }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="!loadingPallets && pallets.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No pallets detected</span>
                    </v-list-item-content>
                  </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="10" :style="{ 'max-height': fullscreen ? '90vh' : '75vh', 'overflow-y': 'auto' }">
              <v-row justify="center">
                <v-col cols="12" :loading="imageLoading" class="text-center">
                  <div v-if="palletDocument.type && palletDocument.type.includes('image/')">
                    <v-img :src="palletDocument.url" contain style="max-height: 70vh" @load="imageLoading = false">
                    </v-img>
                  </div>
                  <div v-else-if="palletDocument.type == 'application/pdf'" class="text-center">
                    <v-row justify="center">
                      <v-col cols="12" sm="9" class="text-center" v-if="palletDocument.url">
                        <div ref="pdfBox" class="text-center">
                          <VuePdfEmbed :width="fullscreen ? 1200 : 750" :source="palletDocument.url"
                            @loaded="imageLoading = false" />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <v-container v-else fill-height>
                    <v-row justify="center" height="100%">
                      <div class="text-center">
                        <span>This file is in a format that cannot be previewed.</span> <br /><br />
                        <v-btn color="primary" style="text-transform: none" @click="downloadDocument(palletDocument)">
                          <v-icon class="mr-1">download</v-icon> Download</v-btn>
                      </div>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-dialog>

    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <file-upload class="my-0 py-0" post-action="" :multiple="true" :drop="true" :drop-directory="true" v-model="fileList"
      ref="uploader">
    </file-upload>
  </div>
</template>
<script>
import axios from "axios";
import dateFormat from "dateformat";
import FileUpload from "vue-upload-component";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
  components: {
    FileUpload,
    VuePdfEmbed
  },
  data: () => ({
    annotated: false,
    consignmentNotes: {
      total: 0,
      data: [],
    },
    contentHeaders: [
      // { text: '', value: 'action', align: 'center', sortable: false },
      { text: '', value: 'icon', align: 'center', sortable: false },
      { text: 'Name', value: 'name' },
      // { text: 'Size', value: 'children', align: 'center', sortable: false },
      { text: 'Last Modified', value: 'updatedAt', align: 'center' },
      { text: 'Channel', value: 'channel', align: 'center' },
      { text: 'Origin', value: 'origin', align: 'center' },
      // { text: 'Shared', value: 'shared', align: 'center', sortable: false },
      // { text: 'Draft/Final', value: 'draftFinal', align: 'center', sortable: false },
      { text: 'Classifications', value: 'classification', align: 'center', sortable: false },
    ],
    corruptNotes: {
      total: 0,
      data: [],
    },
    documentTab: "preview",
    documentKey: 0,
    existingQuery: undefined,
    fileList: [],
    filterDialog: false,
    fullscreen: false,
    imageLoading: false,
    lastIndex: 0,
    loading: false,
    loadingCorrupt: false,

    loadingPallets: false,
    page: 1,
    palletDocument: {},
    pallets: [],
    palletModal: false,
    palletKey: 0,
    params: {
      limit: 14,
      offset: 0,
      search: null,
    },
    pdfRefreshKey: 0,
    queryTimeout: undefined,
    searchCorrupt: null,
    searchPallets: null,
    searchSSCC: null,
    selectedTab: "consignment",
    ssccFilter: false,
    timeout: null,
  }),
  mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#inspectionDocuments'
            })
        }
    },
  computed: {
    filterCorrupt() {
      let result = this.corruptNotes.data;
      if (this.searchCorrupt) {
        result = result.filter((note) =>
          note.name.toLowerCase().includes(this.searchCorrupt.toLowerCase())
        );
      }
      return result;
    },
  },
  watch: {
    fileList: {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.submitFiles();
                }
            },
        },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      // if (this.loading) {
      //   clearTimeout(this.timeout);
      // }
      // this.loading = true;

      // this.timeout = setTimeout(async () => {
      //   this.consignmentNotes = await this.$API.getConsignmentNotes({
      //     params: this.params,
      //   });
      //   this.loading = false;
      // }, 250);
      this.getConsignmentNotes()
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        // if (this.loading) {
        //   clearTimeout(this.timeout);
        // }
        // this.loading = true;

        // this.timeout = setTimeout(async () => {
        //   this.consignmentNotes = await this.$API.getConsignmentNotes({
        //     params: this.params,
        //   });
        //   this.loading = false;
        // }, 250);
        this.getConsignmentNotes()
      },
    },
    "params.search": {
      immediate: true,
      handler() {
        this.getConsignmentNotes()
        // if (this.loading) {
        //   clearTimeout(this.timeout);
        // }
        // this.loading = true;

        // this.timeout = setTimeout(async () => {
        //   this.consignmentNotes = await this.$API.getConsignmentNotes({
        //     params: this.params,
        //   });
        //   this.loading = false;
        // }, 250);
      },
    },
    searchSSCC(val) {
      if (val) {
        this.getSSCCNotes()
      } else {
        this.getConsignmentNotes()
      }
    }
  },
  created() {
    // this.getCorruptUploads();
  },
  methods: {
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    annotate() {
      if (!this.annotated) {
        for (let i = 0; i < this.palletDocument.pages; i++) {
          var canvas = this.$refs["pdf_" + (i + 1)][0].$refs.canvas;
          let width = this.$refs["pdf_" + (i + 1)][0].$refs.canvas.width;
          let height = this.$refs["pdf_" + (i + 1)][0].$refs.canvas.height;
          let ctx = canvas.getContext("2d");
          ctx.lineWidth = "2";
          ctx.strokeStyle = "red";
          let pallets = this.filterPallets(i + 1);
          for (let j = 0; j < pallets.length; j++) {
            let pallet = pallets[j];
            if (pallet.geometry) {
              let palletBox = pallet.geometry.BoundingBox;
              ctx.beginPath();
              ctx.rect(
                5,
                palletBox.Top * height - 5,
                width - 10,
                height * palletBox.Height + 10
              );
              ctx.stroke();
            }
          }
          ctx.stroke();
        }
        this.annotated = true

      } else {
        this.annotated = false
        this.pdfRefreshKey++
      }
    },
    deleteDocument(item, index) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          // this.loading = true
          await this.$API.updateShipmentDocument({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.consignmentNotes.data.splice(index, 1);
          this.documentKey++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    deleteCorruptDocument(item, index) {
      this.$confirm(
        "Are you sure you want to delete this corrupt document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          // this.loading = true
          await this.$API.updateShipmentDocument({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.corruptNotes.data.splice(index, 1);
          this.documentKey++;
          this.getConsignmentNotes()

          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    documentClassifications(document) {
      let result = document.shipmentDocumentClassifications ? document.shipmentDocumentClassifications.filter(x => x.supportingDocument && x.supportingDocument.name) : []
      return result
    },
    async downloadDocument(doc) {
            var hiddenElement = document.createElement("a");
            hiddenElement.href = doc.url;
            hiddenElement.target = "_blank";
            hiddenElement.download = doc.name;
            hiddenElement.click();
        },
    async fetchDocument(doc) {
      this.documentKey++;
      let file = await this.$API.downloadFile({
        key: doc.key,
      });
      doc.file = file.file;
      doc.fileType = file.fileType;
      // setTimeout(() => {
      //   this.annotate();
      // }, 100);
      this.documentKey++;
    },
    filterPallets(page) {
      let result = this.pallets.filter((x) => x.page == page);
      if (this.searchPallets) {
        result = result.filter((pallet) =>
          pallet.sscc.toLowerCase().includes(this.searchPallets.toLowerCase())
        );
      }
      return result;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getCorruptUploads() {
      this.loadingCorrupt = true;
      this.corruptNotes = await this.$API.getCorruptUploads({});
      this.loadingCorrupt = false;
    },
    async getConsignmentNotes() {
      // this.loading = true;
      // this.consignmentNotes = await this.$API.getConsignmentNotes({
      //   params: this.params,
      // });
      // this.loading = false;
      if (this.queryTimeout) {
        clearTimeout(this.queryTimeout);
      }
      if (this.loading && this.existingQuery) {
        this.existingQuery.abort()
      }
      this.loading = true;
      this.queryTimeout = setTimeout(async () => {
        this.existingQuery = new AbortController();
        const signal = this.existingQuery.signal
        this.consignmentNotes = await this.$API.getConsignmentNotes({
          params: this.params,
          signal
        });
        this.loading = false;
      }, 750);
    },
    async getSSCCNotes() {
      // this.loading = true;
      // this.filterDialog = false;
      // this.consignmentNotes = await this.$API.getConsignmentSSCC({
      //   params: {
      //     search: this.searchSSCC,
      //   },
      // });
      // this.loading = false;
      if (this.queryTimeout) {
        clearTimeout(this.queryTimeout);
      }
      if (this.loading && this.existingQuery) {
        this.existingQuery.abort()
      }
      this.loading = true;
      this.queryTimeout = setTimeout(async () => {
        this.existingQuery = new AbortController();
        const signal = this.existingQuery.signal
        this.consignmentNotes = await this.$API.getConsignmentSSCC({
          params: {
            search: this.searchSSCC
          },
          signal
        });
        this.loading = false;
      }, 750);
    },
    getIcon(name) {
      if(name){
        let type = name.split(".");
      let result = null;
      if (type.length > 0) {
        result = type.at(-1).toLowerCase();
      }
      if (result && result.toLowerCase() == "xlsx") {
        result = "xls";
      }
      return `/icons/${result}.svg`;
      } else {
        return null
      }
      
    },
    async submitFiles() {
      let pending = this.fileList.filter((x) => !x.uploaded);
            for (let i = 0; i < pending.length; i++) {
                pending[i].loading = true
                let url = await URL.createObjectURL(pending[i].file);
                pending[i].fileIndex = this.lastIndex
                pending[i].uploaded = true
                this.toDataUrl(url, (data) => {
                    let obj = {
                        name: pending[i].name,
                        data: data,
                        type: pending[i].type,
                        size: pending[i].size,
                        fileIndex: pending[i].fileIndex,
                        uploaded: true
                    };
                    this.submitUpload(obj);
                });
                this.lastIndex++;
            }

      // for (let i = 0; i < this.fileList.length; i++) {
      //   let url = await URL.createObjectURL(this.fileList[i].file);
      //   this.toDataUrl(url, (data) => {
      //     let obj = {
      //       name: this.fileList[i].name,
      //       data: data,
      //       type: this.fileList[i].type,
      //       fileIndex: i,
      //     };
      //     this.submitUpload(obj);
      //   });
        
      // }
    },
    async submitUpload(obj) {
      let Api = axios.create({
        // baseURL: "http://localhost:3000",
        baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai': 'http://localhost:3000',
        headers: {
          Authorization: `${this.$store.state.token}`,
          apikey: `${this.$store.state.orgToken}`,
        },
      });
      obj.loading = true;
      let result = await Api.post("/upload/consignmentNote", obj, {
        onUploadProgress: (progressEvent) => {
                    let find = this.fileList.findIndex(x => x.fileIndex == obj.fileIndex)
                    if (find > -1) {
                        this.fileList[find].progress = Math.ceil(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    }
                }
      });
      result = result.data;
      this.getConsignmentNotes()
      // this.consignmentNotes.data.push(result);
      // this.fileList = [];
      this.documentKey++;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async viewPallets(document) {
      this.palletDocument = document;
      console.log(this.palletDocument)
      if (this.palletDocument.type && (this.palletDocument.type.includes('image/') || this.palletDocument.type.includes('application/pdf'))) {
        this.imageLoading = true
      }
      // if (!this.palletDocument.file && !this.palletDocument.fileType) {
      //   this.fetchDocument(this.palletDocument);
      // }
      this.documentTab = "preview";
      this.loadingPallets = true;

      this.palletModal = true;
      this.pallets = await this.$API.getConsignmentNotePalletData(document.id);
      this.loadingPallets = false;
      this.palletKey++;
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  z-index: 9999;
  position: absolute;
  width: 100%;
  cursor: pointer;
}
</style>
